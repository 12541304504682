@config "../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 100 900;
      font-display: swap;
      src: url('../fonts/inter.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Architekt';
        src: url('../fonts/architekt.otf') format('opentype');
    }
    @font-face {
        font-family: 'Architekt';
        src: url('../fonts/architekt-bold.otf') format('opentype');
        font-weight: bold;
    }
    @font-face {
        font-family: 'PPMori';
        src: url('../fonts/PPMori-Regular.otf') format('opentype');
        font-weight: normal;
    }
    @font-face {
        font-family: 'PPMori';
        src: url('../fonts/PPMori-SemiBold.otf') format('opentype');
        font-weight: bold;
    }

    @font-face {
        font-family: 'Stuart Text';
        src: url('../fonts/StuartTextTrial-Regular.otf') format('opentype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Stuart Text';
        src: url('../fonts/StuartTextTrial-Medium.otf') format('opentype');
        font-weight: 500;
    }

    @font-face {
        font-family: 'Stuart Text';
        src: url('../fonts/StuartTextTrial-Bold.otf') format('opentype');
        font-weight: 700;
    }

    .vail {
        [x-cloak] {
            display: none !important;
        }
        nav.shop-nav {
           @apply max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex;
            &>ul {
                @apply flex justify-between items-center flex-col sm:flex-row gap-x-8 w-full;
                &>li {
                    @apply font-semibold  rounded-t float-left overflow-hidden transition ease-out duration-150;
                    &>a {
                        @apply p-2 inline-block
                    }
                    &.big {
                        @apply text-sm md:text-base lg:text-lg xl:text-xl;
                    }
                    &.small {
                        @apply text-sm md:text-sm lg:text-base xl:text-lg;
                    }
                }
            }
        }
        a.btn, button.btn {
            @apply font-semibold py-2 px-4 border rounded;
            &.btn-blue-outline {
                @apply hover:bg-blue-500 text-blue-700 hover:text-white border-blue-500 hover:border-transparent;
            }
            &.btn-black-outline {
                @apply hover:bg-slate-900 text-black hover:text-white border-slate-950 hover:border-transparent;
            }
        }
    }
}

@layer components {
    .vail {
        .markdown {
            h1 {
                @apply text-2xl font-semibold mb-2 mt-4;
                /* @apply font-display; */

                strong {
                    @apply font-semibold;
                }
            }
            h2 {
                @apply text-xl mb-2 mt-4;
                /* @apply font-display; */
            }
            h3 {
                @apply text-lg mb-1 mt-4;
                /* @apply font-display; */
            }
            h4 {
                @apply font-bold mt-4;
                @apply text-base;
                /* @apply font-display; */
            }

            h5, h6 {
                /* @apply font-display; */
            }

            ul {
                @apply list-disc;
                @apply pl-4;
            }
            li {
                @apply pl-2;
            }
            p {
                @apply my-4;
            }
            
            li, ul, p, h6, h5 {
                @apply text-sm;
            }
            
        }
    }

}

@layer utilities {
    .vail {
        .hx-indicator {
            display: none;
        }
        .htmx-request {
            .hx-indicator, &.hx-indicator {
                display: inline-block;
            }

            .hx-hide, &.hx-hide, &+.hx-hide{
                display: none;
            }
        }
        .htmx-indicator {
            opacity: 0;
        }

        .outer-glow {
            --color: rgba(255, 255, 255, 0.5);
            filter:
                    drop-shadow(20px 20px 25px var(--color))
                    drop-shadow(-20px -20px 25px var(--color))
                    drop-shadow(10px 10px 25px var(--color))
                    drop-shadow(-10px -10px 25px var(--color))
                    drop-shadow(5px 5px 25px var(--color))
                    drop-shadow(-5px -5px 25px var(--color))
                    drop-shadow(0px 0px 25px var(--color));
        }
    }
}



@layer base {
    .vail {
        .flickity-page-dots {
            bottom: 15px !important;
        }
        .flickity-page-dot {
            border: 1px solid #6b7280 !important;
        }
    }
}



.vail {
    @keyframes throbber1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes throbber2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
    @keyframes throbber3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    .throbber {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 15px;
        div {
            position: absolute;
            top: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
            &:nth-child(1) {
                left: 8px;
                animation: throbber1 0.6s infinite;
            }
            &:nth-child(2) {
                left: 8px;
                animation: throbber2 0.6s infinite;
            }
            &:nth-child(3) {
                left: 32px;
                animation: throbber2 0.6s infinite;
            }
            &:nth-child(4) {
                left: 56px;
                animation: throbber3 0.6s infinite;
            }
        }
    }
}


@layer base {
    .vail {
        .field-error {
            @apply border-red-700 text-red-900;
            input {
                @apply border-red-700 text-red-900;
            }
            label {
                @apply text-red-900;
            }
        }
        .field-success {
            @apply border-green-700 text-green-900;
            input {
                @apply border-green-700 text-green-900;
            }
            label {
                @apply text-green-900;
            }
        }
    }

}

.vail {


    .StripeElement {
        @apply bg-transparent border-b-2 border-b-gray-400 focus:bg-sky-50 focus:border-b-2 focus:border-sky-600 focus:ring-1
        focus:rounded focus:shadow-xl h-10 outline-none pl-12 pr-2 ring-gray-300 rounded-none transition w-full
        pt-3
        ;

        &.StripeElement--empty {

        }

        &.StripeElement--focus {
            @apply bg-sky-50 border-b-2 border-sky-600 ring-1 rounded shadow-xl;
        }
    }

    .stripe-group:has(.StripeElement--focus) {
        .stripe-group-focus-within\:font-bold {
            @apply font-bold;
        }
    }

    .stripe-group:has(.StripeElement--complete) {
        @apply border-green-700 text-green-900;

        .StripeElement {
            @apply border-green-700 text-green-900;

            &.StripeElement--focus {
                @apply border-sky-600;
            }
        }

        label {
            @apply text-green-900;
        }
    }

    .stripe-group:has(.StripeElement--invalid) {
        @apply border-red-700 text-red-900;

        .StripeElement {
            @apply border-red-700 text-red-900;

            &.StripeElement--focus {
                @apply border-sky-600;
            }
        }

        label {
            @apply text-red-900;
        }
    }

}
@layer base {
    .vail {
        .django-message {
            @apply mx-10 bg-gradient-to-r  my-1 p-0.5 rounded-lg  overflow-clip;
            div {
                @apply bg-gradient-to-tr from-white/60 to-white/80 p-2 px-4 rounded-md flex flex-row;
                p {
                    @apply grow px-6 ;
                }
                button {
                    @apply text-gray-600 hover:text-black;
                }
            }
            &.success {
                @apply from-lime-400 via-lime-600/50 to-lime-600/60 border-lime-600 text-green-950;
            }
            &.info {
                @apply from-sky-400 via-sky-600/50 to-sky-600/60 border-sky-600 text-indigo-950;
            }
            &.warning {
                @apply from-yellow-400 via-yellow-600/50 to-amber-400/60 border-amber-600 text-amber-950;
            }
            &.error {
                @apply from-red-400 via-red-600/50 to-red-600/60 border-red-600 text-red-950;
            }
        }
    }
}

.vail {
    .global-progress {
        position: fixed;
        top: 0;
        z-index: 1000;
        height: 10px;
        width: 100%;
        background-clip: padding-box;
        overflow: hidden;

        @apply opacity-0 bg-purple-900 shadow-xl border-b border-b-black;

        .indeterminate {



            &:before {
                @apply bg-gradient-to-r from-purple-900 via-blue-500 to-purple-900;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right;
                -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
                animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }

            &:after {
                @apply bg-gradient-to-r from-purple-900 via-purple-600 to-purple-900;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right;
                -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
                infinite;
                animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
                infinite;
                -webkit-animation-delay: 1.15s;
                animation-delay: 1.15s;
            }
        }
    }

    .htmx-request.global-progress-blur-overlay {
        z-index: 10000;
        @apply w-full;
        @apply h-full;
    }

    &.htmx-request *:not(.global-progress-blur-overlay, .global-progress-blur-overlay *) {
        /* filter: blur(1px); */
    }

    &.htmx-request {
        @apply fixed;
        @apply w-screen;
    }

    .htmx-request .global-progress, .htmx-request.global-progress {
        @apply opacity-100;
    }

    @-webkit-keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }
    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }
    @-webkit-keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }
    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }


    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

}

@layer utilities {
    .vail {
        .flip-card {
            @apply relative;
            transform-style: preserve-3d;
            perspective: 1000px;

            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            .inner {

                @apply absolute w-full h-full;
                transition: transform 0.2s;
                transform: rotateY(0deg);

                &.enter {
                    transform: rotateY(90deg);
                }
                &.leave {
                    transform: rotateY(-90deg);
                }

            }
        }

        /*.flip-card {*/
        /*    @apply bg-transparent;*/
        /*    @apply relative;*/
        /*    perspective: 1000px;*/
        /*    @apply w-32 h-32;*/
        /*    .inner {*/
        /*        transform-style: preserve-3d;*/
        /*        @apply absolute w-full h-full;*/
        /*        backface-visibility: hidden;*/
        /*         -webkit-backface-visibility: hidden;*/

        /*        &.animated {*/
        /*            transition: transform 0.8s;*/
        /*        }*/
        /*    }*/
        /*}*/
    }
}
